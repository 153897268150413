import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { FullScreenSpinner } from 'components/Elements';
import { UPLOADS_URL } from 'config';
import { useDesigns } from 'features/designs';
import { motion } from 'framer-motion';

export const RecentDesigns = () => {
  const designsQuery = useDesigns();
  const [currentIndex, setCurrentIndex] = useState(0);

  if (designsQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  if (!designsQuery.data) return null;

  const designs = designsQuery.data
    ?.filter((design) => design.active === true)
    .sort((a, b) => a.position - b.position);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === designs.length - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? designs.length - 1 : prevIndex - 1));
  };

  return (
    <div className="mx-8 md:mx-24 mb-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
        <div className="">
          <div className="header mb-3 flex items-center">
            <div className="text-xl md:text-4xl font-semibold">Recent Designs</div>

            <div className="flex items-center ml-4 md:ml-8">
              <div className="bg-black rounded-full h-1 w-1 md:h-4 md:w-4"></div>
              <div className="bg-black rounded-full h-2 w-2 md:h-8 md:w-8 ml-2 md:ml-4"></div>
            </div>
          </div>

          <div className="text-lg dark:text-gray-100 text-gray-700  mb-4">
            Check out some of our latest designs. We are always updating our collection. You can
            also request for a custom design.
          </div>
        </div>
        <div className="col-span-2 slider-container relative">
          <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
            onClick={prevSlide}
          >
            <ChevronLeft className="text-[#142852]" />
          </button>
          <div className="flex overflow-hidden">
            {designs.map((design, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }} // Starting animation state
                animate={index === currentIndex ? { opacity: 1, y: 0 } : {}}
                exit={{ opacity: 0, y: 50 }} // Exit animation state
                transition={{ duration: 0.5 }} // Transition settings
                className={`w-full ${index === currentIndex ? 'block' : 'hidden'}`}
              >
                <DesignCard
                  image={UPLOADS_URL + '/designs/' + design.images[0]}
                  name={design.title}
                  slug={design.slug}
                />
              </motion.div>
            ))}
          </div>
          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md z-10"
            onClick={nextSlide}
            type="button"
          >
            <ChevronRight className="text-[#142852]" />
          </button>
        </div>
      </div>
    </div>
  );
};

const DesignCard = ({ image, name, slug }) => {
  return (
    <div className="p-4 rounded-xl shadow-lg flex flex-col justify-between z-0">
      <Link to={`/designs/${slug}`} className="relative w-full mb-4 overflow-hidden rounded-md">
        <img src={image} alt={name} className="object-contain w-full h-full" />
        <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
      </Link>
      <div className="text-center text-xl md:text-2xl font-semibold capitalize">{name}</div>
    </div>
  );
};
