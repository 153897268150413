import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import Bg from 'assets/breadcrumb_bg.png';
import { Link, useParams } from 'react-router-dom';
import { Gallery } from '../components/ImageGallery';
import { useDesign } from '../api/getDesign';
import { Button, FullScreenSpinner } from 'components/Elements';
import { Check, Eye, Lock } from 'react-feather';
import { useEffect } from 'react';
import { RequestForPrice } from '../components/RequestForPrice';

export const Design = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const { slug } = useParams();

  const designQuery = useDesign({ slug });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (designQuery.isLoading) {
    <FullScreenSpinner />;
  }

  return (
    <WebLayout>
      <Head title={designQuery.data?.title} description="Verdens" />
      <div className="mb-4">
        <div className="mb-3 mx-4 md:mx-48 text-xs md:text-sm">
          <Link to="/">Home</Link>
          <span className="mx-2">/</span>
          <Link to="/designs">Designs</Link>
          <span className="mx-2">/</span>
          <span className="font-bold">{designQuery.data?.title}</span>
        </div>
        <img src={Bg} alt="bg" className="w-full h-20 object-cover" />
      </div>
      <div className="flex flex-col items-center justify-center mb-12">
        <div className="font-semibold text-center text-3xl mb-3">{designQuery.data?.title}</div>
        <div className="bg-black h-1 w-16"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-24 mx-4 md:mx-48 mb-8 md:md-0">
        <div className="col-span-2">
          {designQuery.data?.images && <Gallery images={designQuery.data?.images} />}
        </div>
        <div className="">
          {designQuery.data?.size && (
            <div className="mt-4">
              <div className="text-lg font-semibold mb-2">Project Size</div>
              <div className="">{designQuery.data?.size}</div>
            </div>
          )}
          <div className="mt-4">
            <div className="text-lg font-semibold mb-2">Brief Description</div>
            <div className="">{designQuery.data?.description}</div>
          </div>
        </div>
      </div>
      <div className="mx-4 md:mx-48 mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="">
            <div className="text-lg font-semibold mb-2">Design Specifications</div>
            <div className="">
              <ul>
                {designQuery.data?.design_specifications?.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-4 md:mx-48 mb-8">
        <div className="flex flex-row justify-end mb-8">
          {user ? (
            <RequestForPrice price={designQuery.data?.price} slug={designQuery.data?.slug} />
          ) : (
            <Button onClick={() => navigate(`/auth/login`)} startIcon={<Lock />}>
              Login to account
            </Button>
          )}
        </div>
        <div className="flex flex-row justify-end">
          <Eye className="mr-2" />
          <span>{designQuery.data?.views} Views</span>
        </div>
      </div>
    </WebLayout>
  );
};
