// src/components/ImageGallery.js
import { UPLOADS_URL } from 'config';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './Gallery.css'; // Import custom styles

export const Gallery = ({ images }) => {
  const items = images.map((image) => ({
    original: `${UPLOADS_URL}/designs/${image}`,
    thumbnail: `${UPLOADS_URL}/designs/${image}`,
  }));

  return (
    <ImageGallery
      items={items}
      showThumbnails={true} // Show thumbnails below the main image
      showFullscreenButton={false} // Hide fullscreen button if not needed
      showPlayButton={false} // Hide play button if not needed
      additionalClass="custom-gallery" // Add a custom class for further styling
    />
  );
};
