import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import PropTypes from 'prop-types';
import Bg from 'assets/breadcrumb_bg.png';
import { Link } from 'react-router-dom';

export const Layout = ({ children, title, caption }) => {
  return (
    <WebLayout>
      <Head title={title} />

      <div className="flex justify-center items-center py-12">
        <div className="max-w-md w-full space-y-8 p-10">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{title}</h2>
            <p className="mt-2 text-center text-sm text-gray-600">{caption}</p>
          </div>
          {children}
        </div>
      </div>
    </WebLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
