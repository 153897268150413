import { MDPreview } from 'components/Elements';
import React from 'react';

export const WhyChooseUs = ({ content }) => {
  return (
    <div>
      <div className="mx-4 md:mx-48 flex flex-col justify-end items-end mb-8 my-12">
        <div className="mx-4 header mb-3 flex items-center">
          <div className="text-3xl md:text-4xl font-semibold">Why Choose Us</div>
          <div className="flex items-center ml-4 md:ml-8">
            <div className="bg-black rounded-full h-1 w-1 md:h-4 md:w-4"></div>
            <div className="bg-black rounded-full h-2 w-2 md:h-8 md:w-8 ml-2 md:ml-4"></div>
          </div>
        </div>
        <div className="mb-8">
          <MDPreview value={content} />
        </div>
      </div>
    </div>
  );
};
