import React from 'react';
import { Facebook, Instagram, Linkedin } from 'react-feather';
import { Link } from 'react-router-dom';
import { useSocials } from './api/getSocials';

export const Footer = () => {
  const socialsQuery = useSocials();

  if (socialsQuery.isLoading) {
    return <> </>;
  }

  return (
    <footer className="bg-[#171717] py-12 px-8 md:px-20 relative">
      <div className="container mx-auto mt-8 text-center text-white">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex flex-col items-start">
            <div className="flex flex-col items-start mb-5">
              <span className="font-bold uppercase text-lg">Need Help?</span>
              <Link to="/contact-us" className="mt-2 text-sm hover:underline">
                Contact Us
              </Link>
            </div>
            <div className="flex flex-col items-start">
              <span className="font-bold uppercase text-lg">Helpful links</span>
              <Link to="/projects/start-a-project" className="mt-2 text-sm hover:underline">
                Start a project
              </Link>
            </div>
          </div>

          <div className="flex flex-col items-start">
            <span className="font-bold uppercase text-lg">ABOUT VERDENS DESIGN GROUP</span>
            <Link to="/about-us" className="mt-2 text-sm hover:underline">
              About Us
            </Link>
            <Link to="/careers" className="mt-2 text-sm hover:underline">
              Careers
            </Link>
            <Link to="/terms-and-conditions" className="mt-2 text-sm hover:underline">
              Terms and conditions
            </Link>
            <Link to="/cookie-notice" className="mt-2 text-sm hover:underline">
              Cookie notice
            </Link>
          </div>

          <div className="">
            <div className="flex flex-col items-start">
              <span className="font-bold uppercase text-lg">Follow Us</span>
              <div className="flex flex-row space-x-4 mt-2">
                <a
                  href={`https://www.facebook.com/${socialsQuery.data?.facebook}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Facebook className="text-white hover:text-gray-400" />
                </a>
                <a
                  href={`https://www.instagram.com/${socialsQuery.data?.instagram}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram className="text-white hover:text-gray-400" />
                </a>
                <a
                  href={`https://www.linkedin.com/${socialsQuery.data?.linkedin}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Linkedin className="text-white hover:text-gray-400" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <p className="text-xs mt-4">&copy; All rights reserved. Verdens Design Group. 2024</p>
      </div>
    </footer>
  );
};
